@import "./../../app/variables.scss";

.root {
  display: inline-block;
  vertical-align: top;
  padding: 15px;
  font-size: 9px;
}

.caption {
  font-size: 12px;
  line-height: 14px;
  color: $color-teal;
}
