.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .circularProgressBar {
    transform: rotate(-90deg); /* Rotates to start from the top */
  }
  
  .percentText {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
  }