@import '~app/mixins';

.button {
  position: relative;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 14px;
  display: inline-block;
  height: 30px;
  outline: none;
  border: 1px solid #DFE1E4;

  .content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      padding-top: 2px;
    }

    svg {
      width: 12px;
      height: 7px;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  a,
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  input[type="file"] {
    opacity: 0;
    cursor: pointer;
  }

  &.solid {
    color: #fff !important;
    border-style: none;
    background-color: #52a0fd;
    background-image: linear-gradient(#64b5ff 0%, #297ccf 100%);

    svg {
      fill: #fff;
    }

    &:hover {
      background-color: #2084fc;
      background-image: linear-gradient(#51a2da 0%, #1569ba 100%);
    }
  }

  &.red {
    border: 1px solid $color-red;
    color: $color-red;

    svg {
      fill: $color-red;
    }

    &:hover,
    &:focus {
      $color-hover: darken($color-red, 20%);

      color: $color-white;
      background-color: $color-red;
    }
  }

  &.iconMode {
    height: 90px;
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;

    .content {
      flex-direction: column-reverse;

      svg {
        width: 35px;
        height: 35px;

        &:not(:first-child) {
          margin-left: 0;
          margin-bottom: 6px;
        }
      }
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &.loading {
    pointer-events: none;

    .content {
      visibility: hidden;
    }

    .spinner {
      display: flex;
    }
  }
}

.dropDownToggle {
  button {
    position: relative;

    .content {
      justify-content: flex-start !important;
    }
  }
}
